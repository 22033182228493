body, html {
    height: 100%;
    margin: 0;
}

.scene {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(to top, #87CEEB, #B0E0E6);
}

.sun {
    position: absolute;
    top: 10%;
    left: 70%;
    width: 100px;
    height: 100px;
    background-color: #FFD700;
    border-radius: 50%;
    z-index: 1;
}

.ocean {
    position: absolute;
    bottom: 20%;
    width: 100%;
    height: 35%;
    background: linear-gradient(to bottom, #1E90FF, #4682B4);
}

.sand {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
    background: #FFDAB9;
}

.item-container {
    position: absolute;
    z-index: 2;
    width: 50%;
    height: 50%;
    top: 25%;
    left: 25%;
    background-color: #3838386c;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
}

.button-center {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
